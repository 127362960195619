<template>
  <ion-page>
    <Header id="top"></Header>
    <ion-content :fullscreen="true">

      <section id="cabecera">
        <img v-if="(category && !subcategory) && category.images.length" class="cover-image" :src="category.images[0]" :alt="category.name"/>
        <img v-if="subcategory && subcategory.images.length" class="cover-image" :src="subcategory.images[0]" :alt="subcategory.name"/>

        <div class="container">
          <h1 class="section-title" v-if="category || subcategory"><strong v-if="category">{{ category.name }}</strong><none v-if="subcategory"> > {{ subcategory.name }}</none></h1>
          <h1 class="section-title" v-if="!category && !subcategory"><strong>Nuestros Productos</strong></h1>
          <p v-if="category && !subcategory" class="category-description">{{ category.description }}</p>
          <p v-if="subcategory" class="category-description">{{ subcategory.description }}</p>

        </div>
      </section>

      <section class="md-section" id="products" style="padding-bottom: 0 !important;">
        <div class="container">

          <ion-grid class="filter-sorter" color="transparent">
            <ion-row>
              <ion-col size="6">
                <ion-select class="sorter" :value="order" placeholder="Ordenar por: Populares" @ionChange="sort" interface="popover">
                  <ion-select-option value="popular">Populares</ion-select-option>
                  <ion-select-option value="new">Novedades</ion-select-option>
                  <ion-select-option value="cheap">Precio: Menor > Mayor</ion-select-option>
                  <ion-select-option value="expensive">Precio: Mayor > Menor</ion-select-option>
                  <ion-select-option value="rating">Valoraciones</ion-select-option>
                </ion-select>
              </ion-col>
              <ion-col size="6">
                <ion-button class="filter" fill="none" color="transparent" @click="openFilters" style="--border-radius: 0">
                  Filtrar
                  <ion-icon slot="end" color="medium" :icon="funnelOutline"></ion-icon>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-grid>
            <ion-row>
              <ion-col size="6" size-xl="3" v-for="product in visibleProducts" :key="product.id">
                <Product :data="product"></Product>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-infinite-scroll
                  @ionInfinite="loadMore($event)" 
                  threshold="1000px" 
                  id="infinite-scroll"
                >
                  <ion-infinite-scroll-content
                    loading-spinner="circular"
                    loading-text="Cargando más productos...">
                  </ion-infinite-scroll-content>
                </ion-infinite-scroll>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </section>
      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonSelect, IonButton, modalController, loadingController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { funnelOutline } from 'ionicons/icons';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { mapState } from 'vuex';

import {getCategoryProducts} from '@/store/firestoreActions';
import Product from '@/components/Product.vue';
import Filters from '@/modules/shop/Filters.vue';
import Rating from 'primevue/rating';

export default defineComponent({
  name: 'Products',
  components: {
    IonContent,
    IonPage,
    Header,
    Footer,
    IonSelect,
    Product,
    IonButton
  },
  data() {
    return {
      category: null,
      subcategory: null,
      order: null,
      filtersOpts: {
        availability: ['Todos','Disponible', 'Bajo Pedido'],
        state: ['Todos','En Oferta'],
      },
      filters: {
        availability: 'Todos',
        state: 'Todos',
        price: [0,500],
        rating: 0
      },
      products: [],
      productsList: [],
      visibleProducts: [0,0,0,0,0,0,0,0]
    }
  },
  computed: {
    ...mapState(['categories'])
  },
  methods: {
    loadMore(ev){
      if(this.visibleProducts.length < this.productsList.length){
        const newProducts = this.productsList.slice(this.visibleProducts.length, (this.visibleProducts.length + 32));
        this.visibleProducts.push(...newProducts);
      }

      ev.target.complete();
    },

    sort(ev){
      switch (ev.detail.value) {
          case 'popular':
            this.productsList = this.productsList.sort((a, b) => b.dateUpdated - a.sales);
            this.visibleProducts = this.productsList.slice(0, 32);
            this.order = ev.detail.value;
          break;

        case 'new':
            this.productsList = this.productsList.sort((a, b) => b.dateCreated - a.dateCreated);
            this.visibleProducts = this.productsList.slice(0, 32);
            this.order = ev.detail.value;
          break;

        case 'cheap':
            this.productsList = this.productsList.sort((a, b) => a.price - b.price);
            this.visibleProducts = this.productsList.slice(0, 32);
            this.order = ev.detail.value;           
          break;
      
        case 'expensive':
            this.productsList = this.productsList.sort((a, b) => b.price - a.price);
            this.visibleProducts = this.productsList.slice(0, 32);
            this.order = ev.detail.value;
          break;

        case 'rating':
            this.productsList = this.productsList.sort((a, b) => b.globalRating - a.globalRating);
            this.visibleProducts = this.productsList.slice(0, 32);
            this.order = ev.detail.value;
          break;
      }
    },
     
    async openFilters(){
      const modal = await modalController
        .create({
          component: Filters,
          componentProps: {
            filters: this.filters,
            filtersOpts: this.filtersOpts,
          },
        })

        await modal.present();

        const resp = await modal.onDidDismiss();
        if(resp.data !== undefined){
          this.filters = resp.data;
          this.filter();
        }

    },

    async filter(){
      const loading = await loadingController
        .create({
          message: 'Filtrando...',
          duration: 3000,
        });

      await loading.present();

      this.productsList = this.products.filter(x => x.active === true);
      this.visibleProducts = this.productsList.slice(0, 32);

      // Availability
      if(this.filters.availability === 'Disponible'){
          this.productsList = this.productsList.filter(x => x.stock > 0);
          this.visibleProducts = this.productsList.slice(0, 32);
      } else if(this.filters.availability === 'Bajo Pedido'){
          this.productsList = this.productsList.filter(x => x.stock <= 0);
          this.visibleProducts = this.productsList.slice(0, 32);
      }

      // State
      if(this.filters.state === 'En Oferta'){
          this.productsList = this.productsList.filter(x => x.offer && x.offer.discount !== undefined);
          this.visibleProducts = this.productsList.slice(0, 32);
      }

      // Price
      if(this.filters.price !== [0,500]){
          this.productsList = this.productsList.filter(x => x.price >= this.filters.price[0] && x.price <= this.filters.price[1]);
          this.visibleProducts = this.productsList.slice(0, 32);
      }


      // Ratings
      if(this.filters.rating !== 0 && this.filters.rating !== null && this.filters.rating !== undefined){
          this.productsList = this.productsList.filter(x => x.globalRating === this.filters.rating);
          this.visibleProducts = this.productsList.slice(0, 32);
      }

      loading.dismiss()
    },
  },
  beforeMount(){
    getCategoryProducts(this.$route.query.c, this.$route.query.sc).then((res) => {
      console.log(res);
      this.products = res;
      this.productsList = this.products.filter(x => x.active === true);
      this.visibleProducts = this.productsList.slice(0, 32);
    });
  },
  mounted() {        
    if(this.$route.query.c){
      this.category = this.categories.find(x => x.id === this.$route.query.c);
      if(this.$route.query.sc){
        this.subcategory = this.categories.find(x => x.id === this.$route.query.sc);
      } else {
        this.subcategory = null;
      }
    }
  },
  watch:{
    $route (to, from){
      if(to.name === "Products"){
        const element = document.getElementById('top');
        element.scrollIntoView()

        getCategoryProducts(this.$route.query.c, this.$route.query.sc).then((res) => {
          this.products = res;
          this.productsList = this.products.filter(x => x.active === true);
          this.visibleProducts = this.productsList.slice(0, 32);
        });

        if(this.$route.query.c){
          this.category = this.categories.find(x => x.id === this.$route.query.c);
          if(this.$route.query.sc){
            this.subcategory = this.categories.find(x => x.id === this.$route.query.sc);
          } else {
            this.subcategory = null;
          }
        }
      }
    }
  },
    setup() {
    return {
      funnelOutline,
    }
  }
});
</script>

<style scoped>
  .cover-image{
    width: 100%; 
    height: 256px;
    object-fit: cover;
    object-position: center;
  }

  #cabecera .section-title{
    padding-top: 4rem;
  }

  .category-description{
    max-width: 960px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sorter{
    background: var(--ion-color-light-shade);
    color: var(--ion-color-dark);
    width: calc(100% - 1rem);
    --padding-end: 16px;
    margin: 0.5rem;
    float: left;
  }

  .filter{
    --background: var(--ion-color-light-shade);
    --color: var(--ion-color-medium);
    width: calc(100% - 1rem);
    height: 45px;
    margin: 0.5rem;
    float: right;
  }

  .filter-sorter{
    overflow: hidden;
    margin-bottom: 1rem;
  }

  @media (min-width: 960px) {
    .sorter{
      width: calc(50% - 1rem);
    }

    .filter{
      width: calc(50% - 1rem);
    }
  }
</style>
