
import { defineComponent } from 'vue';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, modalController } from '@ionic/vue';
import { closeOutline, closeCircleOutline, funnelOutline } from 'ionicons/icons';
import SelectButton from 'primevue/selectbutton';
import Rating from 'primevue/rating';
import Slider from 'primevue/slider';

export default defineComponent({
  name: 'Filters',
  props: ['filtersOpts', 'filters'],
  components: {
      IonHeader,
      IonToolbar,
      IonButtons,
      IonButton,
      IonIcon,
      SelectButton,
      Rating,
      Slider
  },
  data() {
    return {
        filtersAct: this.filters,
    }
  },
  methods:{
      close(){
          modalController.dismiss();
      },

      save(){
          modalController.dismiss(this.filtersAct);
      },

      reset(){
          this.filtersAct = {
              availability: 'Todos',
              state: 'Todos',
              price: [0, 50],
              rating: 0,
          };
      }
  },
  setup() {
    return {
      closeOutline, closeCircleOutline, funnelOutline
    }
  }
});
