<template>
    <ion-header class="ion-no-border">
        <ion-toolbar color="primary">
            <ion-title>Filtros</ion-title>
            <ion-buttons slot="end">
                <ion-button @click="close">
                    <ion-icon :icon="closeOutline" slot="icon-only"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
        <div class="invisible-box">
            <div class="image-box filter">
                <h5 style="padding-bottom: 0.5rem"><strong>Disponibilidad</strong></h5>
                <SelectButton class="filter-selection" v-model="filtersAct.availability" :options="filtersOpts.availability" />
            </div>

            <div class="image-box filter">
                <h5 style="padding-bottom: 0.5rem"><strong>En Oferta</strong></h5>
                <SelectButton class="filter-selection" v-model="filtersAct.state" :options="filtersOpts.state" />
            </div>

            <div class="image-box filter">
                <h5 style="padding-bottom: 1rem"><strong>Precio {{filtersAct.price}}€</strong></h5>
                <Slider v-model="filtersAct.price" :range="true" max="50" :step="10" style="max-width: 80%; margin: 0 0.5rem;" class="priceRange"/>
            </div>

            <div class="image-box filter">
                <h5 style="padding-bottom: 0.5rem"><strong>Valoración</strong></h5>
                <Rating v-model="filtersAct.rating" />
            </div>

            <div class="image-box filter">
                <ion-button color="secondary" shape="round" @click="save">
                <ion-icon slot="start" :icon="funnelOutline"></ion-icon>
                <ion-label>Aplicar</ion-label>
                </ion-button>
                <ion-button color="dark" shape="round" fill="outline" @click="reset" style="margin-left: 0.5rem">
                <ion-icon slot="start" :icon="closeCircleOutline"></ion-icon>
                <ion-label>Reestablecer</ion-label>
                </ion-button>
            </div>
        </div>
    </ion-content>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, modalController } from '@ionic/vue';
import { closeOutline, closeCircleOutline, funnelOutline } from 'ionicons/icons';
import SelectButton from 'primevue/selectbutton';
import Rating from 'primevue/rating';
import Slider from 'primevue/slider';

export default defineComponent({
  name: 'Filters',
  props: ['filtersOpts', 'filters'],
  components: {
      IonHeader,
      IonToolbar,
      IonButtons,
      IonButton,
      IonIcon,
      SelectButton,
      Rating,
      Slider
  },
  data() {
    return {
        filtersAct: this.filters,
    }
  },
  methods:{
      close(){
          modalController.dismiss();
      },

      save(){
          modalController.dismiss(this.filtersAct);
      },

      reset(){
          this.filtersAct = {
              availability: 'Todos',
              state: 'Todos',
              price: [0, 50],
              rating: 0,
          };
      }
  },
  setup() {
    return {
      closeOutline, closeCircleOutline, funnelOutline
    }
  }
});
</script>

<style scoped>
    .filter{
        width: 100%;
        margin-bottom: 2rem;
    }


</style>